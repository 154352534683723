const listProductsTextiles = [
  {
    id: 1,
    src: require("@/images/textiles/img48.jpeg"),
    name: "Bolsas 100% algodón grande y pequeña con diferente cerrado y grosor para su comodidad",
    code: "LM-T001",
    flex: 12,
  },
  {
    id: 2,
    src: require("@/images/textiles/img1.png"),
    name: "Mochila ecólogica en algodon y yute",
    code: "LM-T002",
    flex: 12,
  },
  {
    id: 3,
    src: require("@/images/textiles/img3.png"),
    code: "LM-T003",
    name: "Chalina con bordado",
    flex: 12,
  },
  {
    id: 4,
    src: require("@/images/textiles/img4.png"),
    code: "LM-T004",
    name: "Bolsa reciclable con estampado",
    flex: 12,
  },
  {
    id: 5,
    src: require("@/images/textiles/img5.png"),
    code: "LM-T005",
    name: "Polo 100% algodón con estampado  con impresion fluorescente",
    flex: 12,
  },
  {
    id: 6,
    src: require("@/images/textiles/img6.png"),
    code: "LM-T006",
    name: "Mochila en jersey con estampado",
    flex: 12,
  },
  {
    id: 7,
    src: require("@/images/textiles/img7.png"),
    code: "LM-T007",
    name: "Gorra con tapa cuello y bordado",
    flex: 12,
  },
  {
    id: 8,
    src: require("@/images/textiles/img8.jpg"),
    code: "LM-T008",
    name: "Bolsa de algodón con estampado",
    flex: 12,
  },
  {
    id: 9,
    src: require("@/images/textiles/img9.jpg"),
    code: "LM-T009",
    name: "Polo algodón pima 100% con bordado",
    flex: 12,
  },
  {
    id: 10,
    src: require("@/images/textiles/img10.jpg"),
    code: "LM-T010",
    name: "chaleco en drill nuevo mundo con bordados",
    flex: 12,
  },
  {
    id: 11,
    src: require("@/images/textiles/img11.png"),
    code: "LM-T011",
    name: "Polo en jersey lycra con bordado",
    flex: 12,
  },
  {
    id: 12,
    src: require("@/images/textiles/img12.png"),
    code: "LM-T012",
    name: "Gorra en jersey color azul",
    flex: 12,
  },

  {
    id: 13,
    src: require("@/images/textiles/img13.png"),
    code: "LM-T013",
    name: "Gorra en jersey 100% con estampado",
    flex: 12,
  },
  {
    id: 14,
    src: require("@/images/textiles/img14.jpg"),
    code: "LM-T014",
    name: "Polo en algodón pique 100% con bordado",
    flex: 12,
  },
  {
    id: 15,
    src: require("@/images/textiles/img15.png"),
    code: "LM-T015",
    name: "Polo en algodón pima 100% con bordado",
    flex: 12,
  },
  {
    id: 16,
    src: require("@/images/textiles/img16.jpg"),
    code: "LM-T016",
    name: "Polos en algodón piqué y pima con bordado",
    flex: 12,
  },
  {
    id: 17,
    src: require("@/images/textiles/img17.png"),
    code: "LM-T017",
    name: "Sombrero en algodón con bordado",
    flex: 12,
  },
  {
    id: 18,
    src: require("@/images/textiles/img18.png"),
    code: "LM-T018",
    name: "Gorra-visera con bordados",
    flex: 12,
  },
  {
    id: 19,
    src: require("@/images/textiles/img19.png"),
    code: "LM-T019",
    name: "Polo en jersey con bordado",
    flex: 12,
  },
  {
    id: 20,
    src: require("@/images/textiles/img20.png"),
    code: "LM-T020",
    name: "Casaca en algodón con jersey lycra",
    flex: 12,
  },
  {
    id: 21,
    src: require("@/images/textiles/img21.png"),
    code: "LM-T021",
    name: "Polo 100% algodón con estampado full color",
    flex: 12,
  },
  {
    id: 22,
    src: require("@/images/textiles/img22.png"),
    code: "LM-T022",
    name: "Camisa en algodón con bordado",
    flex: 12,
  },
  {
    id: 23,
    src: require("@/images/textiles/img23.png"),
    code: "LM-T023",
    name: "Casaca Reflectiva",
    flex: 12,
  },
  {
    id: 24,
    src: require("@/images/textiles/img24.png"),
    code: "LM-T024",
    name: "Bolsa de botella biodegradable",
    flex: 12,
  },
  {
    id: 25,
    src: require("@/images/textiles/img25.jpg"),
    code: "LM-T025",
    name: "Bolsa reciclable con estampado",
    flex: 12,
  },
  {
    id: 26,
    src: require("@/images/textiles/img26.png"),
    code: "LM-T026",
    name: "Maletin en tela impermeable",
    flex: 12,
  },
  {
    id: 27,
    src: require("@/images/textiles/img27.png"),
    code: "LM-T027",
    name: "Bolsa biodegradable con impresión",
    flex: 12,
  },
  {
    id: 28,
    src: require("@/images/textiles/img28.png"),
    code: "LM-T028",
    name: "Bolsa en tocuyo con estampado",
    flex: 12,
  },
  {
    id: 29,
    src: require("@/images/textiles/img29.png"),
    code: "LM-T029",
    name: "bolsa biodegradable",
    flex: 12,
  },
  {
    id: 30,
    src: require("@/images/textiles/img30.png"),
    code: "LM-T030",
    name: "Bolsa publicitaria",
    flex: 12,
  },
  {
    id: 31,
    src: require("@/images/textiles/img31.png"),
    code: "LM-T031",
    name: "Maletin/mochila porta laptop",
    flex: 12,
  },
  {
    id: 32,
    src: require("@/images/textiles/img32.jpg"),
    code: "LM-T032",
    name: "Mochilas con bordados y bolsillo interno portalaptop",
    flex: 12,
  },
  {
    id: 33,
    src: require("@/images/textiles/img33.png"),
    code: "LM-T033",
    name: "Bolsa reciclable con estampado",
    flex: 12,
  },
  {
    id: 34,
    src: require("@/images/textiles/img34.png"),
    code: "LM-T034",
    name: "Mandil en tela con bordado",
    flex: 12,
  },
  {
    id: 35,
    src: require("@/images/textiles/img35.png"),
    code: "LM-T035",
    name: "Neceser en diseño  con productos exclusivos",
    flex: 12,
  },
  {
    id: 36,
    src: require("@/images/textiles/img36.png"),
    code: "LM-T036",
    name: "Maletin de tela con llavero impreso",
    flex: 12,
  },
  {
    id: 37,
    src: require("@/images/textiles/img37.png"),
    code: "LM-T037",
    name: "Bolsa porta botella en tela",
    flex: 12,
  },
  {
    id: 38,
    src: require("@/images/textiles/img38.png"),
    code: "LM-T038",
    name: "Bolsa en tela con impresión",
    flex: 12,
  },
  {
    id: 39,
    src: require("@/images/textiles/img39.png"),
    code: "LM-T039",
    name: "Bolsa sobre - Notex",
    flex: 12,
  },
  {
    id: 40,
    src: require("@/images/textiles/img40.png"),
    code: "LM-T040",
    name: "Mochila Porta laptop y correa",
    flex: 12,
  },
  {
    id: 41,
    src: require("@/images/textiles/img41.png"),
    code: "LM-T041",
    name: "Chaleco Mochila espalda",
    flex: 12,
  },
  {
    id: 42,
    src: require("@/images/textiles/img42.png"),
    code: "LM-T042",
    name: "Chaleco Mochila delantero",
    flex: 12,
  },
  {
    id: 43,
    src: require("@/images/textiles/img43.png"),
    code: "LM-T043",
    name: "Polo Perú Algodón",
    flex: 12,
  },
  {
    id: 44,
    src: require("@/images/textiles/img44.png"),
    code: "LM-T044",
    name: "Polo Rusia Algodón",
    flex: 12,
  },
  {
    id: 45,
    src: require("@/images/textiles/img45.png"),
    code: "LM-T045",
    name: "Bolsa con cartuchera con cierre",
    flex: 12,
  },
  {
    id: 46,
    src: require("@/images/textiles/img46.jpg"),
    code: "LM-T046",
    name: "Porta computadora o Tablet de telar",
    flex: 12,
  },
  {
    id: 47,
    src: require("@/images/textiles/img47.png"),
    name: "Casaca Impermeable",
    code: "LM-T047",
    flex: 12,
  },
  {
    id: 2,
    src: require("@/images/textiles/img2.png"),
    code: "LM-T047",
    name: "Gorro con bordado",
    flex: 12,
  }
]

export { listProductsTextiles }
